export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  socketUrl: process.env.REACT_APP_AWS_SOCKET,
  domain: process.env.REACT_APP_DOMAIN,
  redirectUri: process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI,
  authTokenUrl: process.env.REACT_APP_AWS_COGNITO_USER_POOL_AUTH_TOKEN,
  userPoolClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
  oauthResponseType: process.env.REACT_APP_AWS_COGNITO_OAUTH_RESPONSE_TYPE,
  logoutUri: process.env.REACT_APP_AWS_COGNITO_LOGOUT_URI,
  idleTimeout: process.env.REACT_APP_IDLE_TIMEOUT
}
