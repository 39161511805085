import { Autocomplete, Grid, TextField } from '@mui/material'
import useSocketConnection from 'hooks/useSocketConnection'
import { RefObject, useEffect, useState } from 'react'
import { clearAllLegalEntites } from 'redux/slices/legalEntities'
import { clearAllTermsConditions } from 'redux/slices/terms'
import { RootState, useDispatch, useSelector } from 'redux/store'
import { generateID } from 'utils/generateMessageID'
import { Request } from '../../../schemas/request'
import { Orgs } from './section'

type BusinessIDProps = {
  open: boolean
  optionSelected: Orgs
  labelEntered: string
  setLabelEntered: (value: string) => void
  setOption: (value: Orgs) => void
  setValue: (value: Orgs) => void
  label: string
  type: string
  initialValues?: any
  isSelected: boolean
  disabled?: boolean
  clearable?: boolean
  resetTermsRef?: RefObject<any>
  resetIssuer?: RefObject<any>
  handleClear?: any
  setIsSelected: (value: boolean) => void
}

export const BusinessIdAutoComplete = ({
  open,
  optionSelected,
  labelEntered,
  setLabelEntered,
  setOption,
  setValue,
  label,
  type,
  initialValues,
  isSelected,
  disabled,
  clearable = true,
  resetIssuer,
  resetTermsRef,
  handleClear,
  setIsSelected
}: BusinessIDProps) => {
  const [orgsReceived, setOrgsList] = useState<Orgs[]>([])
  const [, setClickedOutside] = useState<boolean>(false)
  const [inFocus, setInFocus] = useState<boolean>(false)
  const [idEntered, setIdEntered] = useState<string>('')
  const { onSocketSend } = useSocketConnection()
  const { entities } = useSelector((state: RootState) => state.legalEntities)
  const { issuers } = useSelector((state: RootState) => state.termsReducer)
  const dispatch = useDispatch()
  useEffect(() => {
    const requestLegalEntityLookup: Request = {
      messageId: generateID(),
      messageType: 'QueryLegalEntities',
      QueryLegalEntities: {
        legalEntityCode: {
          legalEntityCodeData: idEntered?.replace(/ +/g, '')
        }
      }
    }
    if (
      type !== 'issuer' &&
      initialValues.length === 0 &&
      optionSelected?.label === '' &&
      idEntered !== null &&
      idEntered !== '' &&
      idEntered.length > 1
    ) {
      onSocketSend(requestLegalEntityLookup)
    }
  }, [idEntered, optionSelected, type, initialValues, isSelected])

  useEffect(() => {
    if (open) {
      setInFocus(true)
    } else {
      setInFocus(false)
    }
  }, [open, labelEntered])

  // For creating options
  useEffect(() => {
    const orgList: Orgs[] = []
    const localOrgList: Orgs[] = []

    if (entities?.length > 0) {
      entities?.forEach((legal) => {
        orgList.push({
          label: legal?.commonName,
          businessId: legal?.legalEntityIdentifier,
          displayId: legal?.legalEntityCodes[0].legalEntityCodeData,
          type: legal?.legalEntityCodes[0]?.legalEntityCodeType
        })
      })
      setOrgsList(orgList)
    } else if (initialValues?.length > 0) {
      initialValues?.forEach((legal) => {
        if (Object.keys(legal).length !== 0)
          localOrgList.push({
            label:
              legal?.businessUnitActionContext && legal?.businessUnitActionContext.length > 0
                ? legal?.businessUnitActionContext[0]?.legalEntity?.commonName
                : legal?.commonName,
            businessId:
              legal?.businessUnitActionContext && legal?.businessUnitActionContext.length > 0
                ? legal?.businessUnitActionContext[0]?.legalEntity?.legalEntityIdentifier
                : legal.legalEntityIdentifier,
            displayId:
              legal?.businessUnitActionContext && legal?.businessUnitActionContext.length > 0
                ? legal?.businessUnitActionContext[0]?.legalEntity?.legalEntityCodes[0]
                    .legalEntityCodeData
                : 'NA',
            type:
              legal?.businessUnitActionContext && legal?.businessUnitActionContext.length > 0
                ? legal?.businessUnitActionContext[0]?.legalEntity?.legalEntityCodes[0]
                    ?.legalEntityCodeType
                : 'NA'
          })
      })
      setOrgsList(localOrgList)
    }
    const filteredList: Orgs[] = []
    formatLegalEnities(initialValues, entities, type, issuers)?.forEach((org: Orgs) => {
      if (org?.label?.toLowerCase()?.includes(labelEntered?.toLowerCase())) {
        filteredList.push(org)
      }
    })
    labelEntered !== undefined
      ? setOrgsList(filteredList)
      : formatLegalEnities(initialValues, entities, type, issuers)
  }, [entities, initialValues, labelEntered])

  return (
    <Grid item md={12} lg={12}>
      <Autocomplete
        disablePortal
        id={`businessIdLabel-id`}
        options={orgsReceived}
        groupBy={(option: Orgs) => option.type}
        open={inFocus}
        disabled={disabled}
        disableClearable={!clearable}
        onFocus={() => {
          setOrgsList(orgsReceived)
          setInFocus(true)
        }}
        onInputChange={(event: any, value: string, reason: string) => {
          event?.preventDefault()
          event?.stopPropagation()
          if (reason === 'clear') {
            setOption({ label: '', businessId: '', country: '', displayId: '', type: '' })
            setValue({ label: '', businessId: '', country: '', displayId: '', type: '' })
            setLabelEntered('')
            setIdEntered('')
            dispatch(clearAllLegalEntites())
            dispatch(clearAllTermsConditions())
            resetIssuer?.current?.resetIssuerOptions()
            resetTermsRef?.current.resetTerms()
            handleClear('issuer')
          } else {
            //setInFocus(true)
            setIdEntered(value)
          }
        }}
        onChange={(event: any, option: Orgs) => {
          event?.preventDefault()
          event?.stopPropagation()
          setInFocus(false)
          if (!!option) {
            setOption(option)
            setValue(option)
            setIsSelected(true)
          }
        }}
        getOptionLabel={(option: Orgs) => option.displayId}
        isOptionEqualToValue={(option, value) => option.displayId === value.displayId}
        onBlur={() => {
          setInFocus(false)
          setClickedOutside(true)
        }}
        value={
          optionSelected
            ? optionSelected
            : { label: '', businessId: '', country: '', displayId: '', type: '' }
        }
        sx={{ minWidth: 'fit-content' }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`${label} Business ID`}
            placeholder="or Search By Identifier..."
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </Grid>
  )
}
const formatLegalEnities = (initialValues: any, entities: any, type: string, issuers: any[]) => {
  const localOrgList: Orgs[] = []
  if (type !== 'issuer') {
    // This is the result set we will be getting if initialValues are passed as null (user input is required then populates the legal entities)
    entities?.length > 0 &&
      entities?.forEach((legal) => {
        localOrgList.push({
          label: legal?.commonName,
          businessId: legal?.legalEntityIdentifier,
          displayId: legal?.legalEntityCodes[0]?.legalEntityCodeData,
          type: legal?.legalEntityCodes[0]?.legalEntityCodeType
        })
      })
    //This is result set that will be passed on from the parent compenent to child components
    initialValues?.forEach((legal) => {
      if (Object.keys(legal).length !== 0)
        localOrgList.push({
          label: legal?.businessUnitActionContext[0]?.legalEntity?.commonName,
          businessId: legal?.businessUnitActionContext[0]?.legalEntity?.legalEntityIdentifier,
          displayId:
            legal.businessUnitActionContext[0]?.legalEntity?.legalEntityCodes[0]
              ?.legalEntityCodeData,
          type: legal.businessUnitActionContext[0].legalEntity?.legalEntityCodes[0]
            ?.legalEntityCodeType
        })
    })
  } else if (type === 'issuer') {
    issuers?.length > 0 &&
      issuers?.forEach((legal) => {
        localOrgList.push({
          label: legal?.issuerLegalEntity?.commonName,
          businessId: legal?.issuerLegalEntity?.legalEntityIdentifier,
          displayId: legal?.issuerLegalEntity?.legalEntityCodes[0]?.legalEntityCodeData,
          type: legal?.issuerLegalEntity?.legalEntityCodes[0]?.legalEntityCodeType
        })
      })
  }
  return localOrgList
}
