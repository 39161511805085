import { store } from '../store'
// utils

import { Response } from 'schemas/response'
import fetchAndDispatch from 'utils/fetchandDispatch'
import {
  getGuaranteeRequestSuccess,
  hasGetGuaranteeError,
  startGetGuaranteeLoading
} from 'redux/slices/create-guarantee-request'
import {
  hasCreatePayloadError,
  setGuaranteeRequestPayload,
  startCreatePayloadLoading
} from 'redux/slices/create-payload'
import {
  appendGuaranteeSuccess,
  appendGuaranteeSuccessForWorkflow,
  updateGuaranteeWithWorkFlowEvent
} from 'redux/slices/all-guarantees'
import { setHistory } from 'redux/slices/contract-history'
import { appendNotificationSuccess, getAllNotificationsSuccess } from 'redux/slices/notifications'
import { getAllLegalEntitiesSuccess } from 'redux/slices/legalEntities'
import { getAllTermsConditionsSuccess } from 'redux/slices/terms'
import { getUserDetials } from 'redux/slices/user'
import { getAllPurposeTypesSuccess } from 'redux/slices/purposeTypes'
import { getPdf } from 'redux/slices/pdf'
import { getContractPdf } from 'redux/slices/contractPdf'
import { getAllLegals } from 'redux/slices/legals'

// ----------------------------------------------------------------------

export function eventCentral(data: string) {
  const { dispatch } = store

  try {
    const parsedData: Response = JSON.parse(data)
    const {
      Status,
      QueryContractResponse,
      QueryDocumentResponse,
      QueryCreateActionsResponse,
      QueryContractHistoryResponse,
      ContractEvent,
      WorkflowEvent,
      QueryNotificationsResponse,
      NotificationEvent,
      QueryLegalEntitiesResponse,
      QueryPurposeTypesResponse,
      QueryAvailableContractTermsResponse,
      AuthConfirmation,
      QueryContractDocumentResponse,
      QueryLegalDocumentListResponse
    } = parsedData

    switch (parsedData.messageType) {
      case 'Status':
        if (!!Status && Status.status !== 'OK') {
          dispatch(hasGetGuaranteeError(parsedData?.Status))
        }

        break
      case 'Pong':
        // Nothing to do
        break

      case 'ContractEvent':
        dispatch(startGetGuaranteeLoading())
        dispatch(appendGuaranteeSuccess(ContractEvent))
        dispatch(getGuaranteeRequestSuccess(ContractEvent))

        break
      case 'WorkflowEvent':
        const actionTypeReceived =
          WorkflowEvent?.businessUnitActionContext[0]?.availableActions[0]?.bffTripartiteAction
            ?.actionType
        const obj =
          WorkflowEvent?.businessUnitActionContext[0]?.availableActions[0]?.bffTripartiteAction[
            `${actionTypeReceived}`
          ]
        const dataObj = {
          contractType: 'TripartiteContract',
          TripartiteContractState: {
            eventTimestamp: WorkflowEvent?.eventTimestamp,
            contractIdentifier: WorkflowEvent?.contractIdentifier,
            partyGroupRole: WorkflowEvent?.workflowRole?.roleType,
            contractRevision: WorkflowEvent?.contractRevision,
            state: WorkflowEvent?.workflowState,
            stateDisplay: WorkflowEvent?.workflowStateDisplay,
            newExpires: obj?.newExpires,
            commences: obj?.commences,
            expires: obj?.expires,
            applicants:
              WorkflowEvent?.businessUnitActionContext[0]?.availableActions[0]?.bffTripartiteAction
                ?.CREATE?.applicants,
            beneficiaries:
              WorkflowEvent?.businessUnitActionContext[0]?.availableActions[0]?.bffTripartiteAction
                ?.CREATE?.beneficiaries,
            issuers:
              WorkflowEvent?.businessUnitActionContext[0]?.availableActions[0]?.bffTripartiteAction
                ?.CREATE?.issuers,
            amount:
              WorkflowEvent?.businessUnitActionContext[0]?.availableActions[0]?.bffTripartiteAction
                ?.CREATE?.amount,
            purpose:
              WorkflowEvent?.businessUnitActionContext[0]?.availableActions[0]?.bffTripartiteAction
                ?.CREATE?.purpose,
            purposeData:
              WorkflowEvent?.businessUnitActionContext[0]?.availableActions[0]?.bffTripartiteAction
                ?.CREATE?.purposeData,
            terms: {
              termsIdentifier:
                WorkflowEvent?.businessUnitActionContext[0]?.availableActions[0]
                  ?.bffTripartiteAction?.CREATE?.contractTermsIdentifier
            }
          },
          businessUnitActionContext: WorkflowEvent?.businessUnitActionContext
        }
        const workFlowEvents = ['CANCELLED', 'APPROVED', 'SUBMITTED', 'FAILED', 'UPDATED']
        if (WorkflowEvent?.workflowState && workFlowEvents.includes(WorkflowEvent?.workflowState)) {
          dispatch(updateGuaranteeWithWorkFlowEvent(WorkflowEvent))
          dispatch(getGuaranteeRequestSuccess(dataObj))
        } else {
          dispatch(appendGuaranteeSuccessForWorkflow(dataObj))
          dispatch(getGuaranteeRequestSuccess(dataObj))
        }
        break

      case 'QueryLegalEntitiesResponse':
        dispatch(getAllLegalEntitiesSuccess(QueryLegalEntitiesResponse?.entries))
        break

      case 'QueryAvailableContractTermsResponse':
        dispatch(getAllTermsConditionsSuccess(QueryAvailableContractTermsResponse))
        break

      case 'QueryContractResponse':
        fetchAndDispatch(QueryContractResponse, dispatch)
        break

      case 'QueryDocumentResponse':
        if (QueryDocumentResponse) {
          window.sessionStorage.setItem('termsPdf', QueryDocumentResponse?.data)
          dispatch(getPdf(QueryDocumentResponse?.data))
        }
        break

      case 'QueryContractDocumentResponse':
        if (QueryContractDocumentResponse) {
          window.sessionStorage.setItem('contractPdf', QueryContractDocumentResponse?.data)
          dispatch(getContractPdf(QueryContractDocumentResponse?.data))
        }
        break
      case 'QueryPurposeTypesResponse':
        dispatch(getAllPurposeTypesSuccess(QueryPurposeTypesResponse?.entries))
        break

      case 'QueryContractHistoryResponse':
        dispatch(setHistory(QueryContractHistoryResponse))
        break
      case 'QueryNotificationsResponse':
        dispatch(getAllNotificationsSuccess(QueryNotificationsResponse?.entries))
        break
      case 'NotificationEvent':
        dispatch(appendNotificationSuccess(NotificationEvent))
        break

      case 'AuthConfirmation':
        dispatch(getUserDetials(AuthConfirmation))
        break

      case 'QueryCreateActionsResponse':
        dispatch(startCreatePayloadLoading())
        if (Status?.status === 'OK') {
          dispatch(setGuaranteeRequestPayload(QueryCreateActionsResponse.entries))
        } else {
          dispatch(hasCreatePayloadError('Error Occurred while fetching create Payload'))
        }

        break

      case 'QueryLegalDocumentListResponse':
        dispatch(getAllLegals(QueryLegalDocumentListResponse?.legalDocumentList))
        break

      default:
        break
    }
  } catch (error) {
    console.error(error)
  }
}
