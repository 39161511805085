import { createSlice } from '@reduxjs/toolkit'
import { WorkflowEvent } from 'schemas/response'
import Guarantee from '../../@types/guarantee'

type InitalStateType = {
  allGuarantees: Guarantee[]
  isLoading: boolean
  error: null
}
const initialState: InitalStateType = {
  allGuarantees: [],
  isLoading: true,
  error: null
}

const slice = createSlice({
  name: 'allGuarantees',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
      state.allGuarantees = []
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET Guarantees
    getAllGuaranteesSuccess(state, action) {
      state.isLoading = false
      state.allGuarantees = action.payload
      state.error = null
    },
    // Append Guarantee
    appendGuaranteeSuccess(state, action) {
      state.isLoading = false
      state.allGuarantees = updateGuaranteesAndReturn(state.allGuarantees, action.payload)
      state.error = null
    },
    // Append Guarantee
    appendGuaranteeSuccessForWorkflow(state, action) {
      state.isLoading = false
      state.allGuarantees = updateGuaranteesAndReturnForWorkflow(
        state.allGuarantees,
        action.payload
      )
      state.error = null
    },
    updateGuaranteeWithWorkFlowEvent(state, action) {
      state.isLoading = false
      state.allGuarantees = updateGuarantees(state.allGuarantees, action.payload)
      state.error = null
    },
    // CLEAR Guarantees
    clearGuarantees(state) {
      state.isLoading = false
      state.allGuarantees = []
      state.error = null
    }
  }
})

export const {
  getAllGuaranteesSuccess,
  hasError,
  startLoading,
  appendGuaranteeSuccess,
  updateGuaranteeWithWorkFlowEvent,
  appendGuaranteeSuccessForWorkflow,
  clearGuarantees
} = slice.actions

// Reducer
export default slice.reducer

const updateGuaranteesAndReturn = (allGx: Guarantee[], guarantee: Guarantee) => {
  let updatedGx: Guarantee[] = []
  const index = allGx.findIndex(
    (g) =>
      g?.TripartiteContractState?.contractIdentifier ===
      guarantee?.TripartiteContractState?.contractIdentifier
  )
  if (index >= 0) {
    allGx[index] = guarantee
    updatedGx = allGx
  } else {
    console.log('New Guarantee Created>>>>')
    updatedGx = [guarantee, ...allGx]
  }
  return updatedGx
}

const updateGuaranteesAndReturnForWorkflow = (allGx: Guarantee[], guarantee: Guarantee) => {
  let updatedGx: Guarantee[] = []
  const index = allGx.findIndex(
    (g) =>
      g?.TripartiteContractState?.contractIdentifier ===
      guarantee?.TripartiteContractState?.contractIdentifier
  )
  if (index >= 0) {
    allGx[index] = {
      ...allGx[index],
      TripartiteContractState: {
        ...allGx[index].TripartiteContractState,
        state: guarantee.TripartiteContractState.state,
        ...({ newExpires: guarantee.TripartiteContractState.newExpires } &&
          guarantee.TripartiteContractState.newExpires),
        ...({ newAmount: guarantee.TripartiteContractState.newAmount } &&
          guarantee.TripartiteContractState.newAmount),
        ...({ newPurposeData: guarantee.TripartiteContractState.newPurposeData } &&
          guarantee.TripartiteContractState.newPurposeData),
        stateDisplay: guarantee.TripartiteContractState.stateDisplay
      },
      reference: guarantee?.reference !== undefined ? guarantee?.reference : allGx[index].reference,
      businessUnitActionContext: [...guarantee.businessUnitActionContext]
    }
    updatedGx = allGx
  } else {
    console.log('New Guarantee Created>>>>')
    updatedGx = [guarantee, ...allGx]
  }
  return updatedGx
}

const updateGuarantees = (allGuarantees: Guarantee[], workEvent: WorkflowEvent) => {
  let guarantees: Guarantee[] = allGuarantees
  // have to define it
  const index = allGuarantees.findIndex(
    (g) => g?.TripartiteContractState?.contractIdentifier === workEvent?.contractIdentifier
  )
  if (index >= 0) {
    let updatedGx
    if (workEvent.workflowState === 'UPDATED') {
      updatedGx = {
        ...allGuarantees[index],
        reference: workEvent?.reference,
        businessUnitActionContext: [...workEvent.businessUnitActionContext]
      }
    } else {
      updatedGx = {
        ...allGuarantees[index],
        TripartiteContractState: {
          ...allGuarantees[index].TripartiteContractState,
          state: workEvent?.workflowState,
          stateDisplay: workEvent?.workflowStateDisplay
        },
        reference: workEvent?.reference,
        businessUnitActionContext: [...workEvent.businessUnitActionContext]
      }
    }

    allGuarantees[index] = updatedGx as any

    guarantees = allGuarantees
  }
  return guarantees
}
