import { createSlice } from '@reduxjs/toolkit'
import { Legal } from '../../@types/legal'

type InitialStateType = {
  allLegals: Legal[]
  isLoading: boolean
}

const initialState: InitialStateType = {
  allLegals: [],
  isLoading: true
}

const slice = createSlice({
  name: 'legals',
  initialState,
  reducers: {
    getAllLegals(state, action) {
      state.allLegals = [...action.payload]
      state.isLoading = false
    }
  }
})

export const { getAllLegals } = slice.actions

export default slice.reducer
