import { createSlice } from '@reduxjs/toolkit'

type InitalStateType = {
  pdf: string
  isLoading: boolean
  error: null
}
const initialState: InitalStateType = {
  pdf: '',
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: 'pdf',
  initialState,
  reducers: {
    // START LOADING
    startLoadingPDF(state) {
      state.isLoading = true
      state.pdf = ''
    },

    // HAS ERROR
    pdfHasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET
    getPdf(state, action) {
      state.isLoading = false
      state.pdf = action.payload
      state.error = null
    }
  }
})

export const { getPdf, pdfHasError, startLoadingPDF } = slice.actions

// Reducer
export default slice.reducer
