import { Suspense, lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
// layouts
import DashboardLayout from '../layouts/dashboard'
// guards
// components
import { Notifications, MyTasks, OrgTasks } from 'components/dashboard-menu'

import GuaranteeCreation from 'components/request-action/GuaranteeCreation'
import { Box, CircularProgress } from '@mui/material'
import AuthGuard from 'guards/AuthGuard'

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return (
    <Suspense
      fallback={
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      }
    >
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  const children = [
    {
      path: 'auth',
      children: [
        {
          path: 'notVerified',
          element: <AuthGuard>You are not authorised</AuthGuard>
        }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" key="navigate" replace /> },
        { path: 'app', element: <DashboardPage key="dashboard-page" replace /> },
        {
          path: 'tasks',
          element: <MyTasks key="tasks-dashboard" />
        },
        {
          path: 'org-tasks',
          element: <OrgTasks key="orgtasks-dashboard" />
        },
        {
          path: 'notifications',
          element: <Notifications key="notifications" />
        },
        {
          path: 'legals',
          element: <Legals key="legals" />
        },
        { path: 'guarantee/:id', element: <Guarantee key="guarantee-detail" /> },
        { path: 'edit/:id', element: <EditGuarantee key="edit-guarantee" /> },
        {
          path: 'request',
          children: [
            { element: <Navigate to="/" replace /> },
            {
              path: 'create-guarantee',
              element: <GuaranteeCreation key="create-guarantee" />
            },
            {
              path: 'review-guarantee-request',
              element: <RequestReviewPage key="review-guarantee-request" />
            },
            {
              path: 'create-guarantee-applicant',
              element: <GuaranteeCreation key="create-guarantee" />
            },
            {
              path: 'create-guarantee-beneficiary',
              element: <GuaranteeCreation key="create-guarantee" />
            }
          ]
        }
      ]
    },
    { path: '/dashboard/legals', element: <Navigate to="/dashboard/app" replace /> }
  ]
  return useRoutes(children)
}
// Notifications
const DashboardPage = Loadable(lazy(() => import('../pages/dashboard/DashboardPage')))

const Guarantee = Loadable(lazy(() => import('../pages/dashboard/GuaranteeViewPage')))
const RequestReviewPage = Loadable(lazy(() => import('../pages/dashboard/RequestReviewPage')))

const EditGuarantee = Loadable(lazy(() => import('../pages/dashboard/GuaranteeEditPage')))

const Legals = Loadable(lazy(() => import('../pages/dashboard/Legals')))
