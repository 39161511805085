// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

const ROOTS_DASHBOARD = '/dashboard'

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    tasks: path(ROOTS_DASHBOARD, '/tasks'),
    orgTasks: path(ROOTS_DASHBOARD, '/org-tasks'),
    onboarding: path(ROOTS_DASHBOARD, '/onboarding'),
    guarantee: path(ROOTS_DASHBOARD, '/guarantee'),
    notifications: path(ROOTS_DASHBOARD, '/notifications'),
    legals: path(ROOTS_DASHBOARD, '/legals'),
    guaranteeCreate: {
      request: path(ROOTS_DASHBOARD, '/request'),
      createAppl: path(ROOTS_DASHBOARD, '/request/create-guarantee-applicant'),
      createBene: path(ROOTS_DASHBOARD, '/request/create-guarantee-beneficiary'),
      review: path(ROOTS_DASHBOARD, '/request/review-guarantee-request'),
      edit: path(ROOTS_DASHBOARD, '/edit'),
      editRequest: path(ROOTS_DASHBOARD, '/request/edit'),
      pending: path(ROOTS_DASHBOARD, '/pending')
    }
  }
}
