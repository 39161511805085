import { Box, Grid, Typography } from '@mui/material'
import { TermsAndConditions } from '../autocomplete/DropDowns'

export const SectionBreak = ({ children }) => <Box sx={{ marginBottom: '32px' }}>{children}</Box>

export const ElementBreak = ({ children }) => (
  <Grid container item sx={{ marginBottom: '20px' }}>
    {children}
  </Grid>
)

export const Heading = ({ children, ...props }) => (
  <Typography fontSize="18px" color="#192A3E" fontWeight={700} {...props}>
    {children}
  </Typography>
)

export type CreateGxInitialValues = {
  applicant: string
  bene: string
  issuer: string
  amount: string
  number: string
  purposeDescription: string
  comments: string
  expiryDate: string
  isOpenEnded: boolean
  afterSubmit?: string
  reference?: string
  terms: TermsAndConditions['termsDisplay']
}
