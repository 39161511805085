import { fCommasAndDecimals } from 'utils/formatNumber'
import { Amount } from '../../../../@types/guarantee'

const NANO_CONVERTER = 1000000000

export const convertAmountStringToObject = (amountSet: string): Amount => {
  const [units, nanos] = amountSet.toString().split('.')
  return {
    units: parseInt(units.replaceAll(',', '')),
    nanos: parseInt(`${nanos || '00'}0000000`),
    currencyCode: 'AUD'
  }
}

export const getAmountValue = (amount: Amount) => {
  const nanoValueToDecimal = amount?.nanos / NANO_CONVERTER
  return amount?.units + nanoValueToDecimal
}

export const displayUserReadableAmount = (amount: Amount) => {
  return fCommasAndDecimals(getAmountValue(amount))
}

export const defineAmountState = (amount: Amount): string =>
  amount.units > 0 ? displayUserReadableAmount(amount) : ''

export const convertAmountStringToValue = (amount: string): number =>
  parseFloat(amount.replaceAll(',', ''))
