import { Autocomplete, Grid, TextField } from '@mui/material'

import {
  forwardRef,
  RefObject,
  SyntheticEvent,
  useContext,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { GlobalInfoContext, initialState } from 'contexts/GlobalStateContext'
import { RootState, useSelector } from 'redux/store'
import { PurposeTypes } from '../../../../@types/purpose-types'
import { FormikErrors, FormikTouched } from 'formik'

export type Types = {
  label: string
  value: string
}

type PurposeTypesProps = {
  setPurposeType: any
  purposeTypes: PurposeTypes[]
}
export const PurposeTypeSelection = ({ setPurposeType, purposeTypes }: PurposeTypesProps) => {
  const { setReqInfo, initiatedBy, type, id } = useContext(GlobalInfoContext)
  const [purposeOptions, setPurposeOptions] = useState<{ label: string; id: string }[]>([])

  useEffect(() => {
    const purposeList: { label: string; id: string }[] = []
    purposeTypes.forEach((pur: PurposeTypes) => {
      purposeList.push({ label: pur.purposeTypeDisplay, id: pur.purposeTypeIdentifier })
    })
    setPurposeOptions(purposeList)
  }, [purposeTypes])

  return (
    <Grid item md={4} lg={4}>
      <Autocomplete
        disablePortal
        id={`purpose-type-name`}
        value={{
          label: `${type}` ? type : '',
          id: `${id}` ? id : ''
        }}
        options={purposeOptions}
        onInputChange={(_event, _param, reason) => {
          if (reason === 'clear') {
            setPurposeType({ label: '', id: '' })
          }
        }}
        getOptionLabel={(option) => (option?.label ? option?.label : '')}
        isOptionEqualToValue={(opt, value) => opt.label === value.label}
        onChange={(_e: SyntheticEvent, _value, _reason: string) => {
          setPurposeType({
            label: _value?.label ? _value?.label : '',
            id: _value?.id ? _value?.id : ''
          })
          setReqInfo({
            ...initialState,
            initiatedBy,
            type: _value?.label,
            id: _value?.id
          })
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Purpose Type"
            placeholder="Select purpose type"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </Grid>
  )
}

export type TermsAndConditions = {
  termsIdentifier: string
  termsDisplay: string
  deedDisplay: string
  deedIdentifier: string
}

type TermsSelectionProps = {
  setIsTermsSelected: (value: boolean) => void
  handleEntityValue?: (value: TermsAndConditions) => any
  disabled?: boolean
  touched?: FormikTouched<any>
  errors?: FormikErrors<any>
}

export const TermsAndConditionsSelection = forwardRef(
  (
    { setIsTermsSelected, handleEntityValue, disabled, touched, errors }: TermsSelectionProps,
    ref: RefObject<any>
  ) => {
    const { issuers: issu, purpose, setReqInfo, terms } = useContext(GlobalInfoContext)
    const [termsList, setTermsList] = useState<TermsAndConditions[]>([])
    const [termsSelected, setTermsSelected] = useState<{
      termsIdentifier: string
      termsDisplay: string
      deedDisplay: string
      deedIdentifier: string
    }>(terms)

    const { issuers } = useSelector((state: RootState) => state.termsReducer)

    useEffect(() => {
      const list: TermsAndConditions[] = []
      const filteredIssuer = issuers.filter(
        (issuer) => issuer?.issuerLegalEntity?.legalEntityIdentifier === issu[0]?.businessId
      )
      filteredIssuer[0]?.terms?.forEach((contract) => {
        list.push({
          termsIdentifier: contract?.termsIdentifier,
          termsDisplay: contract?.termsDisplay,
          deedDisplay: contract?.deedDisplay,
          deedIdentifier: contract.deedIdentifier
        })
      })
      setTermsList(list)
    }, [issuers, issu])

    useEffect(() => {
      setTermsSelected(terms)
    }, [terms])
    useImperativeHandle(ref, () => ({
      resetTerms() {
        setTermsSelected({
          termsIdentifier: '',
          termsDisplay: '',
          deedDisplay: '',
          deedIdentifier: ''
        })
        setIsTermsSelected(false)
        setReqInfo({
          ...purpose,
          terms: {
            termsIdentifier: '',
            termsDisplay: '',
            deedDisplay: '',
            deedIdentifier: ''
          }
        })
      }
    }))

    return (
      <>
        <Autocomplete
          disablePortal
          id={`tandc-type-name`}
          getOptionLabel={(option: TermsAndConditions) => option?.termsDisplay}
          disabled={disabled}
          isOptionEqualToValue={(opts, value) => opts?.termsIdentifier === value?.termsIdentifier}
          options={termsList}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Terms & Conditions"
              placeholder="Select applicable terms..."
              InputLabelProps={{ shrink: true }}
              error={Boolean(touched?.terms && errors?.terms)}
              helperText={touched?.terms && errors?.terms}
            />
          )}
          disableClearable={true}
          value={termsSelected}
          onChange={(_, value: TermsAndConditions) => {
            setReqInfo({
              ...purpose,
              terms: {
                termsDisplay: value?.termsDisplay,
                termsIdentifier: value?.termsIdentifier,
                deedDisplay: value?.deedDisplay,
                deedIdentifier: value?.deedIdentifier
              }
            })
            setIsTermsSelected(true)
            setTermsSelected(value)
            handleEntityValue && handleEntityValue(value)
          }}
        />
      </>
    )
  }
)
