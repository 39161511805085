import { cognitoConfig } from 'config'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState, createContext } from 'react'
interface WebSocketContextType {
  webSocket: WebSocket | null
  setSocket: Function
}

const WsContext = createContext<WebSocketContextType>({ webSocket: null, setSocket: () => {} })
WsContext.displayName = 'WebSocket context'

function createWebSocket(url: string): WebSocket {
  return new WebSocket(url)
}

const WsContextProvider = (props) => {
  const [webSocket, setSocket] = useState<WebSocket | null>(null)
  const { isAuthenticated } = useAuth()
  useEffect(() => {
    if (webSocket?.readyState === webSocket?.CLOSED && isAuthenticated) {
      setSocket(createWebSocket(cognitoConfig?.socketUrl))
    }
  }, [isAuthenticated, webSocket?.readyState])

  return <WsContext.Provider value={{ webSocket, setSocket }}>{props.children}</WsContext.Provider>
}

export { WsContextProvider, WsContext }
