import { createContext, ReactNode, useEffect, useReducer, useState } from 'react'
import Cookies from 'js-cookie'
// routes
import { useSearchParams } from 'react-router-dom'

// @types
import { ActionMap, AuthState, AWSCognitoContextType } from '../@types/authentication'
//
import { cognitoConfig } from '../config'
import customAxios from 'utils/axiosCustom'
import useSocketConnection from 'hooks/useSocketConnection'
// ----------------------------------------------------------------------

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false
}

enum Types {
  auth = 'AUTHENTICATE',
  logout = 'LOGOUT'
}

type AwsAuthPayload = {
  [Types.auth]: {
    isAuthenticated: boolean
  }
  [Types.logout]: undefined
}

type AwsActions = ActionMap<AwsAuthPayload>[keyof ActionMap<AwsAuthPayload>]

const reducer = (state: AuthState, action: AwsActions) => {
  if (action.type === 'AUTHENTICATE') {
    const { isAuthenticated } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true
    }
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false
    }
  }
  return state
}

const AuthContext = createContext<AWSCognitoContextType | null>(null)
AuthContext.displayName = 'AWSCognito context'

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [searchParams] = useSearchParams()
  const { onSocketClosed } = useSocketConnection()

  const [clientID] = useState(cognitoConfig?.userPoolClientId || '')
  const [redirectUrl] = useState(cognitoConfig?.redirectUri || '')
  const [tokenUrl] = useState(cognitoConfig?.authTokenUrl || '')
  useEffect(() => {
    const fetchData = async () => {
      // Check if refresh_token exists in the cookie
      const jwtToken = Cookies.get('token')
      if (jwtToken) {
        const tokenParts = jwtToken.split('.')
        const payloadDecoded = Buffer.from(tokenParts[1], 'base64').toString()

        const payload = JSON.parse(payloadDecoded)

        const expirationTime = new Date(payload.exp * 1000) // Convert to milliseconds
        const currentTime = new Date()

        if (currentTime >= expirationTime) {
          logout()
        } else {
          dispatch({
            type: Types.auth,
            payload: { isAuthenticated: true }
          })
        }
      } else {
        // Fetch the code from the URL
        const urlParams = searchParams
        const code = urlParams.get('code')

        if (code) {
          try {
            const data = {
              grant_type: 'authorization_code',
              client_id: clientID, // your app client id
              code: code, // the authorization code
              redirect_uri: redirectUrl
            }

            // Make the POST call to the backend
            const response = await customAxios.post(
              `${tokenUrl}/oauth2/token`,
              new URLSearchParams(data),
              {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              }
            )
            // Get the JWT and refresh_token from the response
            const { id_token } = response.data

            // Save the JWT and refresh_token in the cookies
            Cookies.set('token', id_token)
            dispatch({
              type: Types.auth,
              payload: { isAuthenticated: true }
            })
          } catch (error) {
            console.error('Authentication error:', error)
            // Handle error or redirect to login page
            logout()
          }
        } else {
          // Code not present in the URL, handle accordingly
          console.log('Code not found in the URL')
          // Handle error or redirect to login page
          logout()
        }
      }
    }
    const timer = setInterval(() => {
      fetchData()
    }, 2000)
    return () => clearInterval(timer)
  }, [])

  // same thing here
  const logout = async () => {
    // Do some logout stuff

    dispatch({
      type: Types.auth,
      payload: { isAuthenticated: false }
    })
    onSocketClosed()
    Cookies.remove('token')

    window.location.href = `${cognitoConfig.authTokenUrl}/logout?client_id=${cognitoConfig.userPoolClientId}&logout_uri=${cognitoConfig.logoutUri}&redirect_uri=${cognitoConfig.redirectUri}&response_type=${cognitoConfig.oauthResponseType}`
    window.history.replaceState(null, '', '/')
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
