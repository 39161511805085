// material
import { CircularProgress, Box } from '@mui/material'
// redux
import NoNotifications from 'pages/NoNotifications'
import { Guarantee } from '../../@types/guarantee'
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'routes/paths'
import Page from 'components/Page'
import { Table, TableContainer } from './styles/MyTasks.styles'
import TaskCounter from './TaskCounter'
import HeadingTitle from './HeadingTitle'
import { useOrgTasks } from 'hooks/useOrgTasks'
import { fDateWithTimeZone } from 'utils/formatTime'
import { displayUserReadableAmount } from 'components/request-action/sections/forms/utils'

// ----------------------------------------------------------------------

export default function OrgTasks() {
  const navigate = useNavigate()
  const { orgTasks, isLoading } = useOrgTasks()

  return (
    <Page title="Organisation Tasks" sx={{ margin: '0 16px' }}>
      {isLoading && (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )}
      <Box sx={{ marginBottom: '18px' }}>
        <HeadingTitle>Organisation Tasks</HeadingTitle>
      </Box>
      <Box sx={{ marginBottom: '24px' }}>
        <TaskCounter type="org-task" rows={orgTasks} />
      </Box>
      {orgTasks && orgTasks.length > 0 && (
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th>Request Type</th>
                <th>Guarantee ID</th>
                <th>Requested By</th>
                <th>Currency</th>
                <th>Value</th>
                <th>Request Date</th>
              </tr>
            </thead>
            <tbody>
              {orgTasks.length > 0 &&
                orgTasks?.map((row: Guarantee) => (
                  <tr
                    key={row?.TripartiteContractState.contractIdentifier}
                    onClick={() =>
                      navigate(
                        `${PATH_DASHBOARD.general.guarantee}/${row?.TripartiteContractState.contractIdentifier}`
                      )
                    }
                  >
                    <td>{row.organizationTask?.requestType}</td>
                    <td>{row?.TripartiteContractState.contractIdentifier}</td>
                    <td>{row?.organizationTask?.createdBy}</td>
                    <td>{row?.TripartiteContractState?.amount?.currencyCode}</td>
                    <td>{displayUserReadableAmount(row?.TripartiteContractState?.amount)}</td>
                    <td>
                      {row?.organizationTask?.requestDate.includes('UTC' || 'utc')
                        ? fDateWithTimeZone(row?.organizationTask?.requestDate)
                        : row?.organizationTask?.requestDate}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </TableContainer>
      )}
      {!isLoading && orgTasks?.length === 0 && (
        <NoNotifications title="No Tasks" type="organisation tasks" />
      )}
    </Page>
  )
}
