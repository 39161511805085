import { Box, FormControlLabel, FormGroup, Grid, Switch, TextField } from '@mui/material'
import { Dayjs } from 'dayjs'
import { ReactComponent as AccessibleIcon } from '../../../assets/icons/date-picker.svg'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider'
import { useContext } from 'react'
import { GlobalInfoContext } from 'contexts/GlobalStateContext'
import { FormikTouched, FormikValues } from 'formik'

export type Orgs = {
  label: string
  businessId: string
  country?: string
  displayId: string
  type: string
}

type TimeRowProps = {
  startDateValue: Dayjs | null
  endDateValue: Dayjs | null
  setDateValue: (value: any, type: string) => any
  openEnded: boolean
  setOpenEnded: any
  errors?: FormikValues
  touched?: FormikTouched<any>
}
export const TimeRow = ({
  startDateValue,
  endDateValue,
  setDateValue,
  errors,
  touched
}: TimeRowProps) => {
  const { purpose } = useContext(GlobalInfoContext)

  return (
    <>
      <Grid item md={3} sx={{ marginRight: '1rem' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Contract Start Date"
            value={purpose?.contract?.startDate || startDateValue}
            inputFormat="DD/MM/YYYY"
            onChange={(newValue) => setDateValue(newValue, 'startDate')}
            disablePast={true}
            renderInput={(params) => (
              <TextField
                label="Contract Start Date"
                {...params}
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched?.startDate && errors?.startDate)}
                helperText={touched?.startDate && errors?.startDate}
              />
            )}
            components={{
              OpenPickerIcon: AccessibleIcon
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item md={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Contract End Date"
            value={purpose?.contract?.endDate || endDateValue || null}
            inputFormat="DD/MM/YYYY"
            onChange={(newValue) => setDateValue(newValue, 'endDate')}
            disablePast={true}
            components={{
              OpenPickerIcon: AccessibleIcon
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Contract End Date"
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched?.endDate && errors?.endDate)}
                helperText={touched?.endDate && errors?.endDate}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
    </>
  )
}

export const ExpiryDate = ({
  expiryDateValue,
  setExpiryDate,
  openEnded,
  handleOpenEndedDate,
  errors,
  touched
}) => {
  const { expiryDate } = useContext(GlobalInfoContext)

  return (
    <>
      <Grid item md={3} lg={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Expiry Date"
            value={expiryDate || expiryDateValue}
            disabled={openEnded}
            inputFormat="DD/MM/YYYY"
            disablePast={true}
            onChange={(newValue) => setExpiryDate(newValue, 'expiryDate')}
            renderInput={(params) => (
              <TextField
                label="Expiry Date"
                {...params}
                placeholder="Expiry Date"
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched?.expiryDate && errors?.expiryDate)}
                helperText={touched?.expiryDate && errors?.expiryDate}
              />
            )}
            components={{
              OpenPickerIcon: AccessibleIcon
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item md={2} lg={2}>
        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={openEnded} onChange={() => handleOpenEndedDate(!openEnded)} />
              }
              label="No Expiry Date"
            />
          </FormGroup>
        </Box>
      </Grid>
    </>
  )
}
