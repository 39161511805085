import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { FormikErrors, FormikTouched } from 'formik'

const formatNumber = (inputValue) => {
  const cleanedValue = inputValue.replace(/[^0-9.]/g, '') // Remove non-numeric characters except decimal point

  const parts = cleanedValue.split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // Limit the decimal places to a maximum of two
  if (parts[1]) {
    parts[1] = parts[1].slice(0, 2)
  }

  return parts.join('.')
}

const handleInputChange = (value, setAmount) => {
  const splitValue = value.replaceAll(',', '').split('.')
  if (splitValue[0].length < 13) {
    // Restrict the number from starting with a 0
    if ((value.length === 1 && value === '0') || (value.length === 1 && value === '.')) {
      value = ''
    } else {
      value = value.replace(/[^0-9.]/g, '') // Remove non-numeric characters except decimal point
    }

    const formattedValue = formatNumber(value)
    setAmount(formattedValue)
  }
}

const isLastCharADecimal = (amount: string) => amount[amount.length - 1] === '.'
const hasOneDecimal = (amount: string) => amount.split('.')[1].length === 1
const hasNoDecimal = (amount: string) => !amount.includes('.')

const handleBlur = (value: string, setAmount: (value: string) => void) => {
  if (value.length > 0) {
    // Add ".00" if it has no decimal
    if (hasNoDecimal(value)) {
      value += '.00'
      // Add "00" if last char is decimal
    } else if (isLastCharADecimal(value)) {
      value += '00'
      // Add '0' if only one decimal
    } else if (hasOneDecimal(value)) {
      value += '0'
    }
  }
  setAmount(value)
}

type AmountAndCurrencyProps = {
  setCurrency?: (value) => void
  amount: string
  setAmount: (value: string) => void
  touched?: FormikTouched<any>
  errors?: FormikErrors<any>
  values?: AmountAndCurrencyProps
  placeholder?: string
  label?: string
}

export const AmountAndCurrency = ({
  setCurrency,
  amount,
  setAmount,
  touched,
  errors,
  placeholder,
  label
}: AmountAndCurrencyProps) => (
  <>
    <Grid item md={4} lg={4}>
      <TextField
        type="text"
        label={label || 'Value'}
        sx={{ minWidth: '100%' }}
        placeholder={placeholder || 'Enter value'}
        value={amount}
        onBlur={(e) => handleBlur(e.target.value, setAmount)}
        onChange={(e) => handleInputChange(e.target.value, setAmount)}
        InputLabelProps={{ shrink: true, inputMode: 'numeric' }}
        onWheel={(event) => {
          event.stopPropagation()
          event.preventDefault()
        }}
        error={Boolean(touched?.amount && errors?.amount)}
        helperText={touched?.amount && errors?.amount}
      />
    </Grid>
    {setCurrency && (
      <>
        <Grid item md={1} lg={1} />
        <Grid item md={2} lg={2}>
          <FormControl>
            <InputLabel id="input-label">Currency</InputLabel>
            <Select
              labelId={`currency-label`}
              id={`currency-label`}
              label={`currency-name`}
              defaultValue="AUD"
              onChange={setCurrency}
            >
              <MenuItem value="AUD">AUD</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </>
    )}
  </>
)
