import { useState, createContext, useEffect } from 'react'
import { Orgs } from 'components/request-action/sections/section'
import { Amount } from '../@types/guarantee'

type GlobalStateType = {
  jwt: string
  isRequestSubmitted: boolean
  initiatedBy: string
  applicants: Orgs[]
  beneficiary: Orgs[]
  issuers: Orgs[]
  termsIdentifier?: string
  amount: Amount
  reference: string | null
  expiryDate: string
  isOpenEnded: boolean
  type: string
  id: string
  purpose: {
    purposeDescription: string
    contract: {
      startDate: string | null
      endDate: string | null
      number: string | null
      name: string | null
      comments: string | null
    }
    property: {
      name: string | null
      unit: string | null
      street: string | null
      suburb: string | null
      postcode: string | null
      state: string | null
      country: string | null
    }
  }
  terms: {
    termsIdentifier: string
    termsDisplay: string
    deedDisplay: string
    deedIdentifier: string
  }

  isThemeChanged: boolean
  setRequestSubmitted: (value: boolean) => void
  setReqInfo: (value: Object) => void
}
export const initialState: GlobalStateType = {
  jwt: '',
  isRequestSubmitted: true,
  initiatedBy: '',
  applicants: [],
  beneficiary: [],
  issuers: [],
  termsIdentifier: '',
  amount: {
    units: 0,
    currencyCode: 'AUD',
    nanos: 0
  },
  reference: '',
  expiryDate: '',
  isOpenEnded: false,
  type: '',
  id: '',
  purpose: {
    purposeDescription: '',
    contract: {
      startDate: '',
      endDate: '',
      number: null,
      name: null,
      comments: null
    },
    property: {
      name: '',
      unit: '',
      street: '',
      suburb: '',
      postcode: '',
      state: '',
      country: ''
    }
  },
  terms: {
    termsIdentifier: '',
    termsDisplay: '',
    deedDisplay: '',
    deedIdentifier: ''
  },
  isThemeChanged: false,
  setRequestSubmitted: () => {},
  setReqInfo: () => {}
}
const GlobalInfoContext = createContext(initialState)
GlobalInfoContext.displayName = 'InfoContext'

const GlobalInfoContextProvider = (props) => {
  const [info, setInfo] = useState(initialState)

  const setReqInfo = (_mutatedProps) => {
    setInfo({ ...info, ..._mutatedProps })
  }
  const setRequestSubmitted = (value: boolean) => {
    setInfo({ ...info, ...{ isRequestSubmitted: value } })
  }

  useEffect(() => {
    /* TODO document why this arrow function is empty */
  }, [])

  return (
    <GlobalInfoContext.Provider value={{ ...info, setRequestSubmitted, setReqInfo }}>
      {props.children}
    </GlobalInfoContext.Provider>
  )
}

export { GlobalInfoContextProvider, GlobalInfoContext }
