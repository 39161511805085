import { Box, Typography } from '@mui/material'
import AccountStyle from 'layouts/dashboard/styles/accountStyles'

export const AccountExpanded = ({ user }) => {
  return (
    <>
      <AccountStyle
        sx={{
          m: 2,
          bgcolor: 'rgba(24, 144, 255, 0.48)',
          fontFamily: 'Inter',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px'
        }}
      >
        <Box sx={{ ml: 2 }}>
          <Typography variant="statusbarLabel" sx={{ color: 'white' }}>
            {user?.nameLabelDisplay}
            <br />
          </Typography>
          <Typography variant="body2" sx={{ color: 'white' }}>
            {user?.orgLabelDisplay}
          </Typography>
        </Box>
      </AccountStyle>
    </>
  )
}

export default AccountExpanded
