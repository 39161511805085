// routes
import Router from './routes'
// theme
import ThemeConfig from './theme'
import GlobalStyles from './theme/globalStyles'
// hooks
import { useAuth } from './hooks/useAuth'
// components
import NotistackProvider from './components/NotistackProvider'
import ThemePrimaryColor from './components/ThemePrimaryColor'
import CircularProgress from '@mui/material/CircularProgress'
import useSocketConnection from 'hooks/useSocketConnection'
import { generateID } from 'utils/generateMessageID'
import { Request } from 'schemas/request'
import { ReactElement, useEffect, useRef } from 'react'
import { setConnectivity } from 'redux/slices/socket-data'
import { useLocation, useNavigate } from 'react-router'
import useIdleTimeout from 'hooks/useIdleTimeout'
import { SnackbarKey, useSnackbar } from 'notistack'
import { RootState, useDispatch, useSelector } from 'redux/store'
import { Box } from '@mui/material'
import { cognitoConfig } from 'config'

export default function App() {
  const { isAuthenticated, isInitialized, logout } = useAuth()
  const dispatch = useDispatch()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const isWsConnected = useSelector((state: RootState) => state.socketConnect.isConnected)
  const networkStatus = useRef<SnackbarKey>()

  useEffect(() => {
    window.addEventListener('online', checkConnectivity)
    window.addEventListener('offline', checkConnectivity)

    return () => {
      window.removeEventListener('online', checkConnectivity)
      window.removeEventListener('offline', checkConnectivity)
    }
  }, [isWsConnected, navigator.onLine])

  function alertNetworkIssue(pMsg: ReactElement) {
    networkStatus.current = enqueueSnackbar(pMsg, {
      variant: 'error',
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      persist: true
    })
  }

  function clearNetworkIssue() {
    networkStatus.current = undefined
    closeSnackbar(networkStatus.current)
  }
  const checkConnectivity = () => {
    const isConnected = navigator.onLine
    dispatch(setConnectivity(isConnected))
  }
  useEffect(() => {
    if (!navigator.onLine) {
      alertNetworkIssue(
        <div>
          Connection Lost !! Please refresh
          <button style={{ marginLeft: '10 px' }} onClick={() => window.location.reload()}>
            Refresh
          </button>
        </div>
      )
    } else {
      clearNetworkIssue()
    }
  }, [isWsConnected, navigator.onLine])

  const { onSocketSend } = useSocketConnection()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const handleIdle = async () => {
    logout()
    navigate('/auth/login')
  }
  const idleTimeout: number = parseInt(cognitoConfig.idleTimeout) * 60
  const { isIdle } = useIdleTimeout({ onIdle: handleIdle, idleTime: idleTimeout })

  const doCalls = async () => {
    const requestCreatePayload: Request = {
      messageId: generateID(),
      messageType: 'QueryCreateActions',
      QueryCreateActions: {}
    }
    const requestForAllGuarantees: Request = {
      messageId: generateID(),
      messageType: 'QueryContract',
      QueryContract: { criteria: 'lastActioned', limit: 100 }
    }
    const requestNotifications: Request = {
      messageId: generateID(),
      messageType: 'QueryNotifications',
      QueryNotifications: {
        isAcknowledged: false,
        limit: 1000
      }
    }
    const purposeTypes: Request = {
      messageId: generateID(),
      messageType: 'QueryPurposeTypes',
      QueryPurposeTypes: {}
    }

    onSocketSend(requestForAllGuarantees)
    onSocketSend(requestCreatePayload)
    onSocketSend(requestNotifications)
    onSocketSend(purposeTypes)
  }

  useEffect(() => {
    isIdle && handleIdle()
  }, [isIdle])
  useEffect(() => {
    isAuthenticated && doCalls()
  }, [isAuthenticated])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <NotistackProvider>
          <GlobalStyles />
          {isInitialized ? (
            <Router />
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
              <CircularProgress />
            </Box>
          )}
        </NotistackProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  )
}
