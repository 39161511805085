// routes
import { PATH_DASHBOARD } from '../../routes/paths'
// components

import { Icon } from '@iconify/react'
import NotificationsIcon from 'layouts/common/NotificationsIcon'
// ----------------------------------------------------------------------

const sidebarConfig = (noOfTasks: number, noOfNotifications: number, noOfOrgTasks: number) => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: 'Guarantees',
        path: PATH_DASHBOARD.general.app,
        icon: <Icon icon="foundation:thumbnails" width={24} height={24} color="#FFFF" />
      },
      {
        title: 'Notifications',
        path: PATH_DASHBOARD.general.notifications,
        icon: <NotificationsIcon />,
        noOfUnreadItems: noOfNotifications
      },
      {
        title: 'My Tasks',
        path: PATH_DASHBOARD.general.tasks,
        icon: <Icon icon="fluent:tasks-app-20-regular" width={24} height={24} color="#FFFF" />,
        noOfUnreadItems: noOfTasks
      },
      {
        title: 'Organisation Tasks',
        path: PATH_DASHBOARD.general.orgTasks,
        icon: (
          <Icon
            icon="streamline:money-bank-institution-money-saving-bank-payment-finance"
            width={24}
            height={24}
            color="#FFFF"
          />
        ),
        noOfUnreadItems: noOfOrgTasks
      }
    ]
  }
]

export const settingsConfig = [
  {
    subheader: '',
    items: [
      {
        title: 'Legals',
        path: PATH_DASHBOARD.general.legals,
        icon: <Icon icon="mdi:folder-open" width={24} height={24} color="#FFFF" />
      }
    ]
  }
]

export const termsAndConditions = []

export default sidebarConfig
