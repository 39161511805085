import { rando } from '@nastyox/rando.js'

export function generateID() {
  let result = ''
  const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const charactersLength = characters.length
  let counter = 0
  while (counter < 24) {
    result += characters.charAt(Math.floor(rando(0, 1, 'float') * charactersLength))
    counter += 1
  }
  const splittedArray = result?.match(/(.{1,4})/g) || []
  let finalResult = ''
  for (let t = 0; t < splittedArray?.length; t++) {
    finalResult = finalResult.concat(splittedArray[t]).concat('.')
  }

  return `CM${finalResult.substring(2, finalResult.length - 1)}`
}
