import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import { useNavigate } from 'react-router-dom'
import { PATH_DASHBOARD } from 'routes/paths'
import { useState, useContext } from 'react'
import { GlobalInfoContext, initialState } from 'contexts/GlobalStateContext'
import { INFO } from 'theme/palette'
import { Icon } from '@iconify/react'

export default function FadeMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const { setReqInfo } = useContext(GlobalInfoContext)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)
  const handleNavigate = (type: string) => {
    handleClose()
    setReqInfo({ ...initialState, purpose: null, initiatedBy: type })
    const createAs = type === 'applicant' ? 'createAppl' : 'createBene'
    navigate(PATH_DASHBOARD.general.guaranteeCreate[createAs])
  }

  return (
    <>
      <Button
        id="fade-button"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
        sx={{
          borderRadius: '4px',
          width: '200px',
          margin: '30px 20px 0 30px',
          padding: '11px 22px',
          backgroundColor: INFO.main
        }}
      >
        Create Guarantee&nbsp;
        <Icon icon="ph:caret-down-bold" />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiMenuItem-root': {
            '&:hover': {
              backgroundColor: 'rgba(24, 144, 255, 0.16)'
            }
          }
        }}
        PaperProps={{
          style: {
            width: '200px',
            borderRadius: 0
          }
        }}
        TransitionComponent={Fade}
      >
        <MenuItem sx={{ fontSize: '14px' }} onClick={() => handleNavigate('applicant')}>
          Create as Applicant
        </MenuItem>
        <MenuItem sx={{ fontSize: '14px' }} onClick={() => handleNavigate('beneficiary')}>
          Create as Beneficiary
        </MenuItem>
      </Menu>
    </>
  )
}
