import React, { useState, useContext, useRef, useEffect } from 'react'
// // material
import {
  Button,
  Box,
  Grid,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import { useNavigate } from 'react-router-dom'
import { PATH_DASHBOARD } from 'routes/paths'
import { Dayjs } from 'dayjs'
import { ExpiryDate, Orgs } from '../../sections/section'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { EntityDetailsRow } from '../../sections/EntityRow'
import { AmountAndCurrency } from '../../sections/AmountAndCurrency'
import { GlobalInfoContext } from 'contexts/GlobalStateContext'
import {
  TermsAndConditions,
  TermsAndConditionsSelection
} from '../../sections/autocomplete/DropDowns'
import { overrideCurrencyStyle } from '../../styles/styles'
import { dispatch, RootState, useSelector } from '../../../../redux/store'
import { CreateGxInitialValues, ElementBreak, Heading, SectionBreak } from './shared'
import { startLoadingPDF } from 'redux/slices/pdf'
import useSocketConnection from 'hooks/useSocketConnection'
import { convertAmountStringToObject, defineAmountState } from './utils'
import { base64ToPdf, sendTermsCall } from 'pages/dashboard/utils'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    paddingBottom: '2rem',
    justifyContent: 'space-between',
    textAlign: 'right',
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: 20
  }
})

export type InitialValues = CreateGxInitialValues & {
  propertyName: string
  unit: string
  street: string
  suburb: string
  postcode: string
  state: string
  country: string
}

const CommercialLease = () => {
  const classes = useStyles()
  const { onSocketSend } = useSocketConnection()
  const navigate = useNavigate()
  const [, setApplicant] = useState<Orgs>({
    label: '',
    businessId: '',
    country: '',
    displayId: '',
    type: ''
  })
  const [, setBene] = useState<Orgs>({
    label: '',
    businessId: '',
    country: '',
    displayId: '',
    type: ''
  })
  const [, setIssuer] = useState<Orgs>({
    label: '',
    businessId: '',
    country: '',
    displayId: '',
    type: ''
  })

  const [, setCurrency] = useState('AUD')

  const [, setstartDateValue] = React.useState<Dayjs | null>(null)
  const [, setendDateValue] = React.useState<Dayjs | null>(null)
  const [contractExpiryValue, setContractExpiryValue] = React.useState<Dayjs | null>(null)
  const [isTermsClicked, setIsTermsClicked] = useState(false)
  const { isLoading: isTermsPdfLoading } = useSelector((state: RootState) => state.pdfReducer)

  const {
    setReqInfo,
    purpose,
    applicants,
    beneficiary,
    issuers,
    reference,
    isOpenEnded,
    type,
    expiryDate,
    amount: contextAmount,
    terms: contextTerms
  } = useContext(GlobalInfoContext)
  const initiatedBy = location.pathname.includes('applicant') ? 'applicant' : 'beneficiary'

  const [amountSet, setAmount] = useState(defineAmountState(contextAmount))
  const [terms, setTerms] = useState(contextTerms)

  const [, setStartYear] = useState('')
  const [, setStartMonth] = useState('')
  const [, setStartDay] = useState('')
  const [, setContractExpiryYear] = useState('')
  const [, setContractExpiryMonth] = useState('')
  const [, setContractExpiryDay] = useState('')
  const [, setCountry] = useState<string>('')

  const [, setendDateYear] = useState('')
  const [, setendDateMonth] = useState('')
  const [, setendDateDay] = useState('')
  const [isTermsSelected, setIsTermsSelected] = useState(false)

  const { businessUnitActionContexts: buacs } = useSelector(
    (state: RootState) => state.createGuaranteePayload
  )

  const CommercialGuaranteeCreateSchema = Yup.object().shape({
    applicant: Yup.string().required('Applicant Name is required'),
    bene: Yup.string().required('Beneficiary Name is required'),
    issuer: Yup.string().when({
      is: () => initiatedBy === 'beneficiary',
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required('Issuer Name is required')
    }),
    amount: Yup.string().min(1).required('Amount cannot be empty'),
    number: Yup.string().min(3).notRequired(),
    propertyName: Yup.string().min(3).notRequired(),
    unit: Yup.string().min(1).notRequired(),
    street: Yup.string().min(3).notRequired(),
    suburb: Yup.string().min(3).notRequired(),
    postcode: Yup.string()
      .min(4)
      .notRequired()
      .test('specialChars', 'Postcode format not allowed', (value) =>
        /^[a-z0-9-]+$/.test(String(value))
      ),
    purposeDescription: Yup.string().min(3).required('Purpose of the guarantee is required'),
    comments: Yup.string().min(3).notRequired(),
    state: Yup.string().notRequired(),
    terms: Yup.string().required('Please select terms'),
    isOpenEnded: Yup.boolean().notRequired(),
    expiryDate: Yup.date().when(['isOpenEnded'], {
      is: (isOpenEnded: boolean) => isOpenEnded,
      then: Yup.date().notRequired(),
      otherwise: Yup.date().required('Expiry Date is required')
    }),
    reference: Yup.string().notRequired()
  })

  const formik = useFormik<InitialValues>({
    initialValues: {
      applicant: applicants[0]?.label || '',
      bene: beneficiary[0]?.label || '',
      issuer: issuers[0]?.label || '',
      amount: defineAmountState(contextAmount),
      propertyName: purpose?.property?.name || '',
      number: purpose?.contract?.number || '',
      unit: purpose?.property?.unit || '',
      terms: terms.termsDisplay || '',
      street: purpose?.property?.street || '',
      suburb: purpose?.property?.suburb || '',
      postcode: purpose?.property?.postcode || '',
      state: purpose?.property?.state || '',
      country: purpose?.property?.country || 'Australia',
      purposeDescription: purpose?.purposeDescription || '',
      comments: purpose?.contract?.comments || '',
      expiryDate: expiryDate || '',
      isOpenEnded: isOpenEnded || false,
      reference: reference || ''
    },
    validationSchema: CommercialGuaranteeCreateSchema,
    onSubmit: (values) => {
      setReqInfo({
        reference: values?.reference,
        terms,
        amount: convertAmountStringToObject(amountSet),
        expiryDate: values?.expiryDate || '',
        isOpenEnded: values?.isOpenEnded,
        purpose: {
          ...purpose,
          purposeDescription: values?.purposeDescription,
          property: {
            ...purpose?.property,
            name: values?.propertyName || '',
            unit: values?.unit || '',
            street: values?.street || '',
            suburb: values?.suburb || '',
            postcode: values?.postcode || '',
            state: values?.state || '',
            country: values?.country || ''
          },
          contract: {
            number: values?.number,
            comments: values?.comments || ''
          }
        }
      })
      navigate(PATH_DASHBOARD.general.guaranteeCreate.review)
    }
  })
  const { errors, touched, getFieldProps } = formik

  const handleOpenEndedDate = (value) => {
    setReqInfo({
      isOpenEnded: !isOpenEnded,
      expiryDate: null,
      ...purpose
    })
    value && formik.setFieldValue('expiryDate', '')
    setContractExpiryValue(null)
    formik.setFieldValue('isOpenEnded', value)
  }

  const handleTerms = (value: TermsAndConditions) => {
    formik.setFieldValue('terms', value.termsDisplay, true)
    setTerms(value)
    setReqInfo({
      terms: value
    })
  }
  // Usage
  async function executePreviewTerms(documentIdentifier: string, roleIdentifier: string) {
    dispatch(startLoadingPDF())
    await sendTermsCall(documentIdentifier, roleIdentifier, onSocketSend)
  }

  useEffect(() => {
    isTermsClicked && !isTermsPdfLoading && base64ToPdf('terms.pdf', 'termsPdf')
  }, [isTermsPdfLoading, isTermsClicked])

  const handleEntityValue = (value: Orgs, type: string) => {
    if (type === 'applicant') {
      formik.setFieldValue('applicant', value?.label, true)

      setApplicant(value)
      setReqInfo({
        applicants: [value]
      })
    } else if (type === 'beneficiary') {
      formik.setFieldValue('bene', value?.label, true)

      setBene(value)
      setReqInfo({
        beneficiary: [value]
      })
    } else if (type === 'issuer') {
      formik.setFieldValue('issuer', value?.label, true)

      setIssuer(value)
      setReqInfo({
        issuers: [value]
      })
    }
  }
  const handleClear = (type: string) => {
    if (type === 'applicant') {
      formik.setFieldValue('applicant', '', true)
      setReqInfo({
        applicants: []
      })
    } else if (type === 'bene') {
      formik.setFieldValue('bene', '', true)
      setReqInfo({
        beneficiary: []
      })
    } else if (type === 'issuer') {
      formik.setFieldValue('issuer', '', true)

      setReqInfo({
        issuers: []
      })
    }
  }
  const childRef = useRef()
  const childRefForTerms = useRef()

  const handleAmount = (value: string) => {
    formik.setFieldValue('amount', value, true)
    setAmount(value)
  }

  const handleDate = (value: Dayjs, type: string) => {
    const formattedValue = value?.format('YYYY/MM/DD')
    if (type === 'expiryDate') {
      formik.setFieldValue('expiryDate', formattedValue, true)
      setContractExpiryValue(value)
      setContractExpiryYear(formattedValue.split('/')[0])
      setContractExpiryMonth(formattedValue.split('/')[1])
      setContractExpiryDay(formattedValue.split('/')[2])
    } else if (type === 'startDate') {
      formik.setFieldValue('startDate', formattedValue, true)

      setstartDateValue(value)
      setStartYear(formattedValue.split('/')[0])
      setStartMonth(formattedValue.split('/')[1])
      setStartDay(formattedValue.split('/')[2])
    } else if (type === 'endDate') {
      formik.setFieldValue('endDate', formattedValue, true)

      setendDateValue(value)
      setendDateYear(formattedValue.split('/')[0])
      setendDateMonth(formattedValue.split('/')[1])
      setendDateDay(formattedValue.split('/')[2])
    }
    if (type === 'expiryDate') {
      setReqInfo({
        purpose: {
          ...purpose,
          expiryDate: formattedValue,
          contract: {
            ...purpose?.contract
          }
        }
      })
    } else {
      setReqInfo({
        purpose: {
          ...purpose,
          contract: {
            ...purpose?.contract,
            [`${type}`]: formattedValue
          }
        }
      })
    }
  }
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <SectionBreak>
          <ElementBreak>
            <Heading>Parties</Heading>
          </ElementBreak>
          {initiatedBy === 'applicant' ? (
            <div>
              <ElementBreak>
                <EntityDetailsRow
                  label="Applicant Name"
                  type="applicant"
                  touched={touched}
                  errors={errors}
                  key={`applicant-${type}`}
                  getFieldProps={getFieldProps}
                  setValue={(value) => handleEntityValue(value, 'applicant')}
                  initialOption={applicants[0]}
                  handleClear={handleClear}
                  initiatedBy={initiatedBy}
                  initialValues={buacs}
                  clearable={false}
                />
              </ElementBreak>
              <ElementBreak>
                <EntityDetailsRow
                  label="Beneficiary Name"
                  type="bene"
                  touched={touched}
                  key={`bene-${type}`}
                  errors={errors}
                  getFieldProps={getFieldProps}
                  setValue={(value) => handleEntityValue(value, 'beneficiary')}
                  initialOption={beneficiary[0]}
                  handleClear={handleClear}
                  initiatedBy={initiatedBy}
                  initialValues={[]}
                />
              </ElementBreak>
            </div>
          ) : (
            <div>
              <ElementBreak>
                <EntityDetailsRow
                  label="Beneficiary Name"
                  type="bene"
                  touched={touched}
                  errors={errors}
                  key={`bene-${type}`}
                  getFieldProps={getFieldProps}
                  setValue={(value) => handleEntityValue(value, 'beneficiary')}
                  initialOption={beneficiary[0]}
                  handleClear={handleClear}
                  initiatedBy={initiatedBy}
                  initialValues={buacs}
                  clearable={false}
                />
              </ElementBreak>
              <ElementBreak>
                <EntityDetailsRow
                  label="Applicant Name"
                  type="applicant"
                  touched={touched}
                  errors={errors}
                  key={`applicant-${type}`}
                  getFieldProps={getFieldProps}
                  setValue={(value) => handleEntityValue(value, 'applicant')}
                  initialOption={applicants[0]}
                  handleClear={handleClear}
                  initiatedBy={initiatedBy}
                  initialValues={[]}
                />
              </ElementBreak>
            </div>
          )}
          {initiatedBy === 'applicant' && (
            <ElementBreak>
              <EntityDetailsRow
                label="Issuer Name"
                type="issuer"
                touched={touched}
                errors={errors}
                key={`issuer-${type}`}
                getFieldProps={getFieldProps}
                setValue={(value) => handleEntityValue(value, 'issuer')}
                initialOption={issuers[0]}
                handleClear={handleClear}
                initiatedBy={initiatedBy}
                initialValues={buacs}
                ref={childRef}
                clearable={false}
              />
            </ElementBreak>
          )}
        </SectionBreak>
        <SectionBreak>
          <Box sx={{ flexGrow: 1, ...classes }}>
            <Grid container sx={{ marginTop: '2rem' }}>
              <Heading>Guarantee Purpose</Heading>
            </Grid>
          </Box>
          <Box>
            <ElementBreak>
              <Grid item md={9} lg={9}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Property Name"
                  sx={{ marginTop: '2rem', width: '100%' }}
                  placeholder="Enter Property Name"
                  id="propertyName"
                  name="propertyName"
                  onChange={formik.handleChange('propertyName')}
                  value={formik.values.propertyName}
                  error={Boolean(touched.propertyName && errors.propertyName)}
                  helperText={touched.propertyName && errors.propertyName}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
            </ElementBreak>
            <ElementBreak>
              <Grid container spacing={1}>
                <Grid item md={3} lg={3}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Unit Number"
                    name="unit"
                    id="unit"
                    sx={{ width: '100%' }}
                    placeholder="Enter Unit name"
                    value={formik.values.unit}
                    onChange={formik.handleChange('unit')}
                    error={Boolean(touched.unit && errors.unit)}
                    helperText={touched.unit && errors.unit}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item md={5.5} lg={5.5}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Street Address"
                    name="street"
                    id="street"
                    sx={{ width: '100%' }}
                    placeholder="Enter Street Address"
                    value={formik.values.street}
                    onChange={formik.handleChange('street')}
                    error={Boolean(touched.street && errors.street)}
                    helperText={touched.street && errors.street}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
              </Grid>
            </ElementBreak>
            <ElementBreak>
              <Grid container spacing={1}>
                <Grid item md={4} lg={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Suburb/City"
                    name="suburb"
                    id="suburb"
                    sx={{ width: '100%' }}
                    placeholder="Suburb/City"
                    value={formik.values.suburb}
                    onChange={formik.handleChange('suburb')}
                    error={Boolean(touched.suburb && errors.suburb)}
                    helperText={touched.suburb && errors.suburb}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item md={4.5} lg={4.5}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Postcode"
                    name="postcode"
                    id="postcode"
                    sx={{ width: '100%' }}
                    placeholder="Postcode"
                    value={formik.values.postcode}
                    onChange={formik.handleChange('postcode')}
                    error={Boolean(touched.postcode && errors.postcode)}
                    helperText={touched.postcode && errors.postcode}
                    inputProps={{
                      maxLength: 15
                    }}
                  />
                </Grid>
              </Grid>
            </ElementBreak>
            <ElementBreak>
              <Grid container spacing={1}>
                <Grid item md={4} lg={4}>
                  <Autocomplete
                    options={['NSW', 'Victoria']}
                    value={formik.values.state}
                    getOptionLabel={(option) => (option ? option : '')}
                    onChange={(_, value) => formik.setFieldValue('state', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="State"
                        id={''}
                        placeholder="Search By State..."
                        {...getFieldProps('state')}
                        error={Boolean(touched['state'] && errors['state'])}
                        helperText={touched['state'] && errors['state']}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4.5} lg={4.5}>
                  <Grid item md={2} lg={2}>
                    <FormControl>
                      <InputLabel id="input-label">Country</InputLabel>
                      <Select
                        labelId={`country-label`}
                        id={`country-label`}
                        label={`country-name`}
                        defaultValue="Australia"
                        onChange={(event) => setCountry(event?.target.value)}
                      >
                        <MenuItem value="Australia">Australia</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </ElementBreak>
          </Box>

          <ElementBreak>
            <Grid item md={4} lg={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Contract Number"
                placeholder="Enter Contract number"
                id="number"
                name="number"
                onChange={formik.handleChange('number')}
                value={formik.values.number}
                error={Boolean(touched.number && errors.number)}
                helperText={touched.number && errors.number}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item md={1} lg={1} />
          </ElementBreak>
          <ElementBreak>
            <Grid item md={9} lg={9}>
              <TextField
                id="purpose-of-the-guarantee"
                multiline
                rows={10}
                placeholder="The detailed description of the contract."
                label="Purpose of the Guarantee"
                value={formik.values.purposeDescription}
                onChange={formik?.handleChange('purposeDescription')}
                size="medium"
                style={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched.purposeDescription && errors.purposeDescription)}
                helperText={touched.purposeDescription && errors.purposeDescription}
                inputProps={{ maxLength: 1000 }}
              />
            </Grid>
          </ElementBreak>
          <Box sx={{ flexGrow: 1, ...classes }}>
            <Grid container item spacing={2}>
              <Grid item md={9} lg={9}>
                <TextField
                  id="description"
                  multiline
                  rows={10}
                  placeholder="Provide any additional comments here."
                  label="Optional Comments"
                  value={formik.values.comments}
                  onChange={formik?.handleChange('comments')}
                  size="medium"
                  style={{ width: '100%' }}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(touched.comments && errors.comments)}
                  helperText={touched.comments && errors.comments}
                  inputProps={{ maxLength: 1000 }}
                />
              </Grid>
            </Grid>
          </Box>
        </SectionBreak>
        <Box sx={{ flexGrow: 1, ...classes }}>
          <ElementBreak>
            <Heading>Guarantee Details</Heading>
          </ElementBreak>
        </Box>
        <Box>
          <ElementBreak>
            <ExpiryDate
              expiryDateValue={contractExpiryValue}
              setExpiryDate={handleDate}
              openEnded={formik.values.isOpenEnded}
              handleOpenEndedDate={handleOpenEndedDate}
              errors={errors}
              touched={touched}
            />
          </ElementBreak>
        </Box>
        <Box>
          <ElementBreak>
            <AmountAndCurrency
              setCurrency={setCurrency}
              amount={amountSet}
              setAmount={handleAmount}
              touched={touched}
              errors={errors}
            />
          </ElementBreak>
        </Box>
        <Box>
          <ElementBreak>
            <Grid item md={4} lg={4}>
              <TermsAndConditionsSelection
                setIsTermsSelected={setIsTermsSelected}
                handleEntityValue={handleTerms}
                ref={childRefForTerms}
                errors={errors}
                touched={touched}
              />
            </Grid>
            <Grid item md={1} lg={1} />
            <Grid item md={2} lg={2}>
              {isTermsSelected && (
                <a
                  href="#"
                  onClick={() => {
                    setIsTermsClicked(true)
                    executePreviewTerms(
                      terms?.termsIdentifier,
                      buacs[0]?.businessUnitActionContext[0]?.workflowRole?.roleIdentifier || null
                    )
                  }}
                >
                  Preview Terms
                </a>
              )}
            </Grid>
          </ElementBreak>
        </Box>

        <SectionBreak>
          <Grid item md={4} lg={4}>
            <ElementBreak>
              <Heading>Internal Reference Number</Heading>
            </ElementBreak>
            <TextField
              label="IRN (Optional)"
              InputLabelProps={{ shrink: true }}
              value={formik?.values?.reference}
              onChange={formik?.handleChange('reference')}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
        </SectionBreak>

        <Box>
          <Grid container spacing={3}>
            <Grid container item spacing={2} />
            <Grid container item spacing={2}>
              <Grid item md={3}>
                <Button variant="outlined" sx={{ ...overrideCurrencyStyle }}>
                  Exit
                </Button>
              </Grid>
              <Grid item md={5}>
                <Button
                  variant="contained"
                  type="submit"
                  data-testid="review-and-submit-btn"
                  id="review-and-submit-btn"
                >
                  Review And Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  )
}

export default CommercialLease
