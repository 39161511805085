import { useEffect, useContext } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
// material
import { alpha } from '@mui/material/styles'
import { Box, Stack, Drawer, Divider, Typography } from '@mui/material'
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer'

// components
import { ReactComponent as LygonArc } from '../../assets/icons/arc-white.svg'

import NavSection, { Footer } from '../../components/NavSection'
//
import sidebarConfig, { settingsConfig, termsAndConditions } from './SidebarConfig'
import { RootStyle } from './styles/index.b'
import AccountExpanded from 'layouts/common/AccountExpanded'
import IssuerLogo from 'components/IssuerLogo'
import BeneficiaryLogo from 'components/BeneficiaryLogo'
// import Logout from 'layouts/common/Logout'
import { Icon } from '@iconify/react'
import FadeMenu from './CreateButtonNavigation'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { styled } from '@mui/material/styles'
// ----------------------------------------------------------------------
import { GlobalInfoContext, initialState } from 'contexts/GlobalStateContext'

import { useAuth } from 'hooks/useAuth'
import useSocketConnection from 'hooks/useSocketConnection'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import { clearGuarantees } from 'redux/slices/all-guarantees'
import { clearAllNotifications } from 'redux/slices/notifications'
import { dispatch } from 'redux/store'

const DRAWER_WIDTH = 280
const COLLAPSE_WIDTH = 110

type DashboardSidebarProps = {
  isOpenSidebar: boolean
  onCloseSidebar: VoidFunction
  noOfTasks: number
  noOfNotifications: number
  noOfOrgTasks: number
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
  color: 'white',
  backgroundColor: '#04297A'
})

const NavItem = styled('div')`
  padding: 10px 0 10px 32px;
  cursor: pointer;

  & a {
    display: flex;
    color: white;
    font-size: 14px;
    text-decoration: none;
    & svg {
      margin-right: 15px;
    }
  }
  &:hover {
    background-color: rgba(145, 158, 171, 0.08);
  }
`

const getLogo = (user) => {
  const role = user?.roles
  switch (role) {
    case 'admin':
      return <LygonArc />
    case 'issuer':
      return <IssuerLogo />
    case 'applicant':
      return <BeneficiaryLogo />
    case 'beneficiary':
      return <BeneficiaryLogo />
    default:
      return <LygonArc />
  }
}

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  noOfTasks,
  noOfNotifications,
  noOfOrgTasks
}: DashboardSidebarProps) {
  const { pathname } = useLocation()
  const { user } = useSelector((state: RootState) => state.userReducer)
  const { businessUnitActionContexts: buacs } = useSelector(
    (state: RootState) => state.createGuaranteePayload
  )
  const sideConfig = sidebarConfig(noOfTasks, noOfNotifications, noOfOrgTasks)
  const { isCollapse, collapseHover, onHoverEnter, onHoverLeave } = useCollapseDrawer()

  const navigate = useNavigate()
  const { logout } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const { onSocketClosed } = useSocketConnection()

  const globalContext = useContext(GlobalInfoContext)

  const handleLogout = async () => {
    try {
      globalContext.setReqInfo(initialState)
      dispatch(clearAllNotifications())
      dispatch(clearGuarantees())

      onSocketClosed()

      await logout?.()
      navigate('/auth/login')
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Unable to logout', { variant: 'error' })
    }
  }

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <Box
      sx={{
        height: 1,
        backgroundColor: '#04297A',
        color: 'white',
        '& a': {
          fontSize: '14px',
          color: 'white',
          textDecoration: 'none'
        },
        overflow: 'hidden'
      }}
      key="scroll-bar"
    >
      <div>
        <Stack
          spacing={3}
          sx={{
            px: 2.5,
            pt: 3,
            pb: 2,
            ...(isCollapse && {
              alignItems: 'center'
            })
          }}
        >
          <Stack key="stack-nav" direction="row" alignItems="center" justifyContent="space-between">
            <Box
              key="stack-box"
              component={RouterLink}
              to="/dashboard/app"
              sx={{ display: 'inline-flex' }}
            >
              {getLogo(user)}
            </Box>
          </Stack>
        </Stack>

        <AccountExpanded user={user} />
        <NavSection
          key="nav-section-desktop"
          navConfig={sideConfig}
          isShow={true}
          className="sidebar"
        />
        {buacs.length > 0 && <FadeMenu />}
        <Divider sx={{ mt: 3 }} />
        <NavSection
          key="nav-section-desktop-settings"
          navConfig={settingsConfig}
          isShow={true}
          className="setting-sidebar"
        />
        <NavItem>
          <a href="#" onClick={handleLogout}>
            <Icon icon="mdi-light:logout" width={24} height={24} color="#FFFF" />
            Log Out
          </a>
        </NavItem>
      </div>

      <Stack
        key="stack-nav"
        direction="column"
        justifyContent="space-between"
        sx={{ margin: '60px 0 60px 26px' }}
      >
        <Box sx={{ margin: '6px' }}>
          <Typography variant="body2">Need help? Contact Support:</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            margin: '5px'
          }}
        >
          <Icon
            icon="fluent:chat-help-24-regular"
            width={24}
            height={24}
            style={{ marginRight: '10px' }}
            color="#FFFF"
          />
          <a href="mailto:support@lygon.com">support@lygon.com</a>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            margin: '5px'
          }}
        >
          <Icon
            icon="solar:phone-outline"
            width={24}
            height={24}
            style={{ marginRight: '10px' }}
            color="#FFFF"
          />
          <Typography variant="body2">1300 156 703</Typography>
        </Box>
      </Stack>

      <Container>
        <div>
          <Footer items={termsAndConditions} />
        </div>
      </Container>
    </Box>
  )

  return (
    <RootStyle
      sx={{
        width: {
          lg: DRAWER_WIDTH,
          zIndex: 1
        }
      }}
    >
      <div>
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)',
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </div>
    </RootStyle>
  )
}
